var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 用户管理 ")]},proxy:true},{key:"input",fn:function(){return [_c('span',{staticClass:"tag"},[_vm._v("注册时间: ")]),_c('a-range-picker',{attrs:{"placeholder":['注册开始时间', '注册结束时间']},on:{"change":_vm.onTimeChange}}),_c('span',{staticClass:"template"},[_c('a-select',{staticStyle:{"width":"120px"},attrs:{"default-value":"1"},on:{"change":_vm.changeType}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("姓名")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("手机号")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v("身份证号")]),_c('a-select-option',{attrs:{"value":"4"}},[_vm._v("学历")]),_c('a-select-option',{attrs:{"value":"5"}},[_vm._v("用户名")])],1),_c('a-input',{attrs:{"placeholder":_vm.keyPlaceholder,"allowClear":""},model:{value:(_vm.keyValue),callback:function ($$v) {_vm.keyValue=$$v},expression:"keyValue"}})],1),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("搜索")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"3",attrs:{"tab":"机构"}})],1),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.userId; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"scroll":{ x: 1200 },"pagination":{
        total: _vm.total,
        current: _vm.queryParams.pageNum,
        defaultPageSize: _vm.queryParams.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + i + 1)+" ")])]}},{key:"userType",fn:function(item){return [_vm._v(" "+_vm._s(_vm.userType(item))+" ")]}},{key:"operation",fn:function(item){return [(_vm.queryParams.user_type != 0)?_c('div',{staticClass:"btn_router_link"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.goDetails(item)}}},[_vm._v("详情")]),_vm._v(" | "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.showModal(item)}}},[_vm._v("修改身份证号码")]),_vm._v(" | "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){_vm.userId=item.userId,_vm.mobile=item.mobile,_vm.isResetPswShow=true}}},[_vm._v("重置密码")])],1):_c('div',[_vm._v("-")])]}}])})],1),_c('a-modal',{attrs:{"title":"修改身份证号","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"list"},[_c('span',[_vm._v("姓名：")]),_c('a-input',{staticClass:"input",attrs:{"placeholder":"输入姓名"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"list"},[_c('span',[_vm._v("身份证号：")]),_c('a-input',{staticClass:"input",attrs:{"placeholder":"输入身份证号"},model:{value:(_vm.formData.idNo),callback:function ($$v) {_vm.$set(_vm.formData, "idNo", $$v)},expression:"formData.idNo"}})],1)])]),_c('a-modal',{attrs:{"align":"center","title":"重置密码","width":"360px"},on:{"ok":function($event){return _vm.onResetPsw()}},model:{value:(_vm.isResetPswShow),callback:function ($$v) {_vm.isResetPswShow=$$v},expression:"isResetPswShow"}},[_c('span',[_vm._v("是否确认重置该账号密码")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }