<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 用户管理 </template>
      <template #input>
        <span class="tag">注册时间: </span>
        <a-range-picker
          @change="onTimeChange"
          :placeholder="['注册开始时间', '注册结束时间']"
        />
        <span class="template">
          <a-select default-value="1" style="width: 120px" @change="changeType">
            <a-select-option value="1">姓名</a-select-option>
            <a-select-option value="2">手机号</a-select-option>
            <a-select-option value="3">身份证号</a-select-option>
            <a-select-option value="4">学历</a-select-option>
            <a-select-option value="5">用户名</a-select-option>
          </a-select>
          <a-input
            v-model="keyValue"
            :placeholder="keyPlaceholder"
            allowClear
          />
        </span>
        <a-button type="primary" @click="onSearch" class="btn">搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="3" tab="机构"></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="(item) => item.userId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        :scroll="{ x: 1200 }"
        @change="onPage"
        :pagination="{
          total: total,
          current: queryParams.pageNum,
          defaultPageSize: queryParams.pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>

        <template slot="userType" slot-scope="item">
          {{ userType(item) }}
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link" v-if="queryParams.user_type != 0">
            <router-link to @click.native="goDetails(item)">详情</router-link>
            |
            <router-link to @click.native="showModal(item)">修改身份证号码</router-link>
            |
            <router-link to @click.native="userId=item.userId,mobile=item.mobile,isResetPswShow=true">重置密码</router-link>
          </div>
          <div v-else>-</div>
        </template>
      </a-table>
    </div>
    <a-modal
      title="修改身份证号"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="list">
          <span>姓名：</span>
          <a-input
            class="input"
            v-model="formData.name"
            placeholder="输入姓名"
          />
        </div>
        <div class="list">
          <span>身份证号：</span>
          <a-input
            class="input"
            v-model="formData.idNo"
            placeholder="输入身份证号"
          />
        </div>
      </div>
    </a-modal>
    <!-- 重置密码 -->
    <a-modal v-model="isResetPswShow" align="center" @ok="onResetPsw()" title="重置密码" width="360px">
      <span>是否确认重置该账号密码</span>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "用户名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "身份",
    align: "center",
    dataIndex: "userType",
    scopedSlots: { customRender: "userType" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idNo",
  },
  {
    title: "所在地",
    align: "center",
    dataIndex: "locationName",
  },
  {
    title: "学历",
    align: "center",
    dataIndex: "degree",
  },
  {
    title: "注册时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      keyType: 1, //查询类型 1姓名 2手机号 3身份证号 4学历 5用户名
      keyPlaceholder: "请输入姓名", //默认提示
      keyValue: "",
      queryParams: {
        pageNum: 1, //页码
        pageSize: 10, // 页数
        start_time: "",
        end_time: "",
        keyword: "", //用户名
        name: "", //姓名
        mobile: "", //手机号
        idNo: "", //身份证号
        degree: "", //学历
        user_type: "1", // 用户类型  1.家长，2从业者，3.机构，4.家长+从业者
      },
      // 弹窗内数据
      ModalText: "Content of the modal",
      visible: false,
      isResetPswShow: false,
      confirmLoading: false,
      formData: {},
      userId:'',
      mobile:'',
    };
  },
  // 事件处理器
  methods: {
    // 改变查询条件
    changeType(e) {
      console.log(e);
      this.keyType = e;
      this.queryParams.keyword = ""; //用户名
      this.queryParams.name = ""; //姓名
      this.queryParams.mobile = ""; //手机号
      this.queryParams.idNo = ""; //身份证号
      this.queryParams.degree = ""; //学历
      this.keyPlaceholder = "";
      // 姓名
      if (this.keyType == 1) {
        this.keyPlaceholder = "请输入姓名";
      }
      // 手机号
      else if (this.keyType == 2) {
        this.keyPlaceholder = "请输入手机号";
      }
      // 身份证号
      else if (this.keyType == 3) {
        this.keyPlaceholder = "请输入身份证号";
      }
      // 学历
      else if (this.keyType == 4) {
        this.keyPlaceholder = "请输入学历";
      }
      // 用户名
      else if (this.keyType == 5) {
        this.keyPlaceholder = "请输入用户名";
      }
    },
    // 分页
    onPage(e) {
      this.queryParams.pageNum = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.getManageList();
    },
    userType(e) {
      if (e == 0) {
        return "普通用户";
      } else {
        let userTypeArry = [
          "家长",
          "从业者",
          "机构",
          "家长+从业者",
          "家长+机构",
          "从业者+机构",
          "家长+从业者+机构",
        ];
        return userTypeArry[e - 1];
      }
    },
    // tab切换
    callback(e) {
      this.queryParams.pageNum = 1;
      this.queryParams.user_type = e;
      this.getManageList();
    },
    // 时间选择
    onTimeChange(date, dateString) {
      this.queryParams.start_time = dateString[0]; // 开始日期
      this.queryParams.end_time = dateString[1]; // 结束日期
    },
    // 跳详情页
    goDetails(e) {
      if (this.queryParams.user_type == 3) {
        // 机构时详情功能暂无
        this.$message.warning("功能暂未开通");
        return false;
      }
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { userId: e.userId, userType: this.queryParams.user_type },
      });
    },
    // 高级检索
    onSearch() {
      this.queryParams.pageNum = 1;
      this.getManageList();
    },
    getManageList() {
      // 1姓名 2手机号 3身份证号 4学历 5用户名
      if (this.keyType == 1) {
        this.queryParams.name = this.keyValue;
      } else if (this.keyType == 2) {
        this.queryParams.mobile = this.keyValue;
      } else if (this.keyType == 3) {
        this.queryParams.idNo = this.keyValue;
      } else if (this.keyType == 4) {
        this.queryParams.degree = this.keyValue;
      } else if (this.keyType == 5) {
        this.queryParams.keyword = this.keyValue;
      }
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/user/info/list",
        params: this.queryParams,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.tableData = res.data.records;
            this.total = res.data.total;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    // 打开弹窗
    showModal(e) {
      // console.log(e);
      // 使用这种方式能避免双向绑定
      for (const key in e) {
        this.formData[key] = e[key];
      }
      this.visible = true;
    },

    // 重置密码
    onResetPsw(){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/user/info/manage/update/userPassword?userId=" + this.userId + '&mobile=' + this.mobile,
        // params: {
        //   userId: this.userId,
        //   mobile: this.mobile,
        // }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.isResetPswShow = false
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 提交
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      // 非空校验 姓名
      if (!this.formData.name) {
        this.$message.warning("请输入姓名");
        return;
      }
      // 非空校验 用户名
      if (!this.formData.idNo) {
        this.$message.warning("请输入用户名");
        return;
      }
      let array = this.formData.idNo.split("");
      let idno = "";
      // 字符串匹配
      for (let index = 0; index < this.formData.idNo.length; index++) {
        if (array[index] == "x") {
          array[index] = "X";
        }
        idno += array[index];
      }
      // console.log(idno);

      this.$ajax({
        method: "post",
        url: "/hxclass-management/user/info/idCard/Update",
        params: {
          userId: this.formData.userId,
          idNo: idno,
          name: this.formData.name,
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.visible = false;
            this.$message.success(res.message);
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
      setTimeout(() => {
        this.confirmLoading = false;
      }, 1000);
    },
    // 取消
    handleCancel(e) {
      this.visible = false;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-template {
  margin-top: 0;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.content {
  margin: 10px auto;
  padding: 0 30px;
  .list {
    display: flex;
    margin: 10px 0;
    align-items: center;
    span {
      display: block;
      flex-shrink: 0;
      width: 80px;
    }
    .input {
      width: 300px;
    }
  }
}
</style>
